@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Playfair';
    src: url('/fonts/Playfair-Display.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 14px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #033159;
  min-height: 50px;
  border-radius: 6px;
  border: 3px solid #f8f8f8;
}

.slick-slider {
  width: 100%;
}

.inputText {
  position: relative;
  font-size: 14px;
  width: 295px;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 16px;
}

.floating-label-new {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 12px;
}

.inputText:focus-within .floating-label-new {
  top: -0.5px;
  font-size: 12px;
}

.inputText:focus-within .floating-label {
  top: 4px;
  font-size: 12px;
}

.transformed-label {
  top: 4px;
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style-type: disc;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
}

.floating-form {
  width: 295px;
}

.select-floating-label {
  position: relative;
}

.floating-select {
  font-size: 16px;
  padding-top: 16px;
  padding-left: 16px;
  display: block;
  width: 100%;
  height: 48px;
  background-color: transparent;
  border: 1px solid rgba(201, 198, 198, 1);
  border-radius: 11px;
}

.another-pet-btn {
  background: linear-gradient(
      0deg,
      rgba(255, 240, 239, 0.39),
      rgba(255, 240, 239, 0.39)
    ),
    #ffffff;
}

.select-label {
  position: absolute;
  color: #585858;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  left: 16px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-select:focus ~ label {
  top: 4px;
  left: 16px;
  font-size: 12px;
  color: #6b6b6b;
}

.select-transformed-label {
  top: 4px;
  left: 16px;
  font-size: 12px;
  color: #6b6b6b;
}

.floating-select:active {
  background: url('/icons/Vector.svg') 94% / 4% no-repeat;
}

select {
  padding: 2px 35px 5px 5px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('/icons/polygon.svg') 94% / 4% no-repeat;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
